.league {
    padding-bottom: 50px;

    .banner {
        display: block;
        width: 100%;
    }

    .con {
        padding: 20px;

        .title {
            font-size: 18px;
        }

        .ant-btn {
            width: 100%;
            background-color: #FC7116;
            border: none;
            height: 45px;
            font-size: 16px;
            border-radius: 10px;
            margin-top: 20px;
        }
    }

    // .title{
    //     margin-top: 30px;
    //     text-align: center;
    //     color: #FC7116;
    //     font-weight: 800;
    //     font-size: 22px;
    // }
    // .slogan{
    //     margin-top: 20px;
    //     text-align: center;
    //     color: #FC7116;
    //     font-weight: 800;
    //     font-size: 14px;
    // }
    // .describe{
    //     padding: 30px 20px;
    //     text-indent: 28px;
    //     color: #333;
    // }
    // .phone{
    //     padding: 30px 20px;
    //     text-align: right;
    //     color: #FC7116;
    // }
    // .logo{
    //     display: block;
    //     width: 50%;
    //     margin: auto;
    //     margin-top: 120px;
    // }
}