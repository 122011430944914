#root{
    height: 100%;
}
.entrance{
    height: 100%;
    background: url(../images/bg.jpg) center no-repeat;
    background-size: cover;
    display: flex;
    align-items: center;
    justify-content: center;
    .form{
        display: flex;
        align-items: center;
        flex-direction: column;
        width: 420px;
        background-color: #fff;
        border-radius: 5px;
        padding: 20px 30px;
        .logo{
            max-width: 35%;
            max-height: 80px;
            display: block;
            margin-bottom: 10px;
        }
        .name{
            font-size: 26px;
            margin-bottom: 20px;
        }
        .title{
            font-size: 18px;
            margin-bottom: 20px;
            width: 100%;
        }
        .ant-form-item{
            width: 100%;
        }
        .ant-btn{
            border-radius: 30px;
        }
        .register{
            margin: 10px 0;
            margin-top: 20px;
            width: 100%;
            display: flex;
            justify-content: space-between;
            a{
                &:nth-child(2){
                    color: #bbb;
                }
            }
            b{color: #1890ff;font-weight: normal;}
        }
        .line{
            width: 100%;
            color: #aaa;
            display: flex;
            align-items: center;
            margin: 20px 0;
            div{
                flex-grow: 1;
                height: 1px;
                background-color: #ddd;
            }
            span{
                margin: 0 10px;
            }
        }
        .other{
            font-size: 22px;
            .anticon{
                border: 1px solid #aaa;
                padding: 5px;
                border-radius: 50%;
                cursor: pointer;
                transition-duration: 300ms;
                +span{
                    margin-left: 50px;
                }
                &:hover{
                    color: #fff;
                }
            }
            .phone{
                color: #fa8c16;
                border-color: #fa8c16;
                &:hover{
                    background-color: #fa8c16;
                }
            }
            .wx{
                color: rgb(0, 200, 0);
                border-color: rgb(0, 200, 0);
                &:hover{
                    background-color: rgb(0, 200, 0);
                }
            }
        }
        .captcha{
            .ant-form-item-control-input-content{
                display: flex;
                .ant-btn{
                    min-width: 102px;
                    height: 39.8px;
                    margin-left: 20px;
                }
            }
        }
    }
    .copyright{
        position: fixed;
        bottom: 10px;
        color: #aaa;
        a{
            margin:0 10px;
            color: #aaa;
            &:hover{
                text-decoration: underline;
            }
        }
    }
}